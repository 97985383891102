import * as echarts from "echarts";
export const sportsLoadListEnums = [
  {
    name: "持续运动",
    dec: "120<bpm<180",
    key: "continuousCount",
    value: 0,
  },
  {
    name: "热身状态",
    dec: "bpm<120",
    key: "warmCount",
    value: 0,
  },
  {
    name: "极限预警",
    dec: "bpm>180",
    key: "extremeCount",
    value: 0,
  },
];

export const Accuracy_Ranking_Column = [
  {
    prop: "rank",
    align: "center",
    label: "排名",
  },
  {
    prop: "class_name",
    align: "center",
    label: "班级",
  },
  {
    prop: "name",
    align: "center",
    label: "姓名",
  },
  {
    prop: "gender",
    align: "center",
    label: "性别",
  },
  {
    prop: "heart_avg",
    align: "center",
    label: "心率",
  },
];

// 运动指标 枚举
export const Sport_Indicators = [
  {
    title: "参与人数",
    value: 0,
    img: "screen_052.png",
    unit: "",
    id: 1,
    key: "participants",
    numType: "num",
  },
  {
    title: "平均心率",
    value: 0,
    img: "screen_043.png",
    unit: "次/分",
    id: 2,
    key: "averageRate",
    numType: "num",
  },
  {
    title: "最高心率",
    value: 0,
    img: "screen_044.png",
    unit: "次/分",
    id: 3,
    key: "averageMaxRate",
    numType: "num",
  },
  {
    title: "平均运动密度",
    value: 0,
    img: "screen_045.png",
    unit: "%",
    id: 4,
    key: "averageDensity",
    numType: "num",
  },
  {
    title: "平均运动强度",
    value: 0,
    img: "screen_046.png",
    unit: "%",
    id: 5,
    key: "averageIntensity",
    numType: "num",
  },
  {
    title: "靶心率运动时间",
    value: 0,
    img: "screen_047.png",
    unit: "",
    id: 6,
    key: "movementTime",
    numType: "time",
  },
];

export const Average_Hear_Rate_Line = {
  xAxis: {
    type: "category",
    data: [],
    axisLine: {
      lineStyle: {
        color: "#666666",
      },
    },
    axisTick: {
      lineStyle: {
        color: "#666666",
      },
    },
    axisLabel: {
      color: "#fff",
    },
  },
  yAxis: {
    type: "value",
    axisLine: {
      lineStyle: {
        color: "#fff",
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "solid",
        color: ["rgba(32, 107, 160, 1)"],
      },
    },
    min: 40,
    // max: 150
  },
  color: ["#4B7FE1", "#68BBC4", "#DB1794"],
  grid: {
    left: "10%",
    bottom: "10%",
    right: "5%",
    top: "20%",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    backgroundColor: "rgba(6, 143, 199, 1)",
    borderWidth: 0,
    textStyle: {
      color: "#fff",
    },
  },
  legend: {
    data: ["平均心率", "男生平均心率", "女生平均心率"],
    textStyle: {
      color: "#fff",
      fontSize: 12,
    },
    itemWidth: 20,
    itemHeight: 8,
    itemGap: 18,
    top: 10,
  },
  series: [
    {
      data: [],
      type: "line",
      smooth: true,
      name: "平均心率",
      lineStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgba(53, 210, 255, 1)" },
          { offset: 1, color: "rgba(19, 184, 255, 1)" },
        ]),
      },
      symbolSize: 10,
      itemStyle: {
        color: "rgba(58, 195, 251, 1)",
      },
    },
    {
      data: [],
      type: "bar",
      name: "男生平均心率",
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgba(3, 101, 213, 0.65)" },
          { offset: 1, color: "rgba(3, 101, 213, 1)" },
        ]),
      },
    },
    {
      data: [],
      type: "bar",
      name: "女生平均心率",
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgba(218, 87, 97, 0.65)" },
          { offset: 1, color: "rgba(218, 87, 97, 1)" },
        ]),
      },
    },
  ],
};
