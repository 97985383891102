<template>
  <div class="violet-contain wb-100 hb-100">
    <div class="violet-head align-center space-between t-w pl-30 pr-30">
      <div class="head-left fz-20 wb-20 row-left">{{ downTime }}</div>
      <div class="head-center fz-36 row-center wb-60 pt-10">
        博才咸嘉小学-智慧体育大课间
      </div>
      <div class="head-right wb-20 row-right">
        <div class="w-30 h-30 pointer icon-screen" @click="handleScreen">
          <img
            :src="imageUrl + 'screen_034.png'"
            alt=""
            class="wb-100 hb-100"
          />
        </div>
      </div>
    </div>
    <div class="violet-centent align-center pt-12">
      <!-- 左边 -->
      <div class="hb-100 wb-25 pl-27 violet-left">
        <div class="wb-100 hb-50">
          <trampoline-card
            className="wb-100 hb-100"
            cardTitle="运动指标"
            cardTitleClassName="card-title"
            cardClassName="wb-100 hb-100"
          >
            <div class="wb-100 hb-100 align-center flex-wrap">
              <div
                class="align-center indocators-item"
                v-for="(item, index) in sportIndicators"
                :key="index"
              >
                <div class="w-70 h-70">
                  <img
                    :src="imageUrl + item.img"
                    alt=""
                    class="wb-100 hb-100"
                  />
                </div>
                <div class="ml-12">
                  <div class="item-title">{{ item.title }}</div>
                  <div class="align-center">
                    <div class="fz-34 tw-bold align-center item-num">
                      <numberAnimation
                        :value="item.value"
                        :animation_time="1"
                        :numType="item.numType"
                        class="fz-34 item-num"
                        separator=","
                      />
                      <div
                        class="fz-14 item-unit ml-6"
                        v-if="item.unit && item.value"
                      >
                        {{ item.unit }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </trampoline-card>
        </div>
        <div class="wb-100 hb-50">
          <trampoline-card
            className="wb-100 hb-100"
            cardTitle="心率数据"
            cardTitleClassName="card-title"
            cardClassName="wb-100 hb-100"
          >
            <div class="wb-100 hb-100">
              <div class="wb-100 hb-100 relative">
                <div class="wb-100 hb-100 mt-12 hidden" ref="tableBoxRef">
                  <div class="wb-100 hb-5 align-center">
                    <!-- 表头 -->
                    <div
                      class="center hb-100 fz-18 c-e0e9ef th"
                      v-for="(item, index) in columns"
                      :key="index"
                      :class="`wb-${Math.floor(100 / columns.length)}`"
                    >
                      {{ item.label }}
                    </div>
                  </div>
                  <div class="wb-100 hb-95 pt-10 pb-10">
                    <div class="wb-100 hb-100 auto">
                      <template v-if="dataList && dataList.length">
                        <TransitionGroup
                          name="fade"
                          class="relative wb-100 hb-100 fz-16"
                        >
                          <div
                            class="tr wb-100 h-40 fz-16 mb-6 br-4 hidden"
                            v-for="(item, index) in dataList"
                            :key="item.id"
                          >
                            <div class="center wb-100 hb-100">
                              <div
                                class="center td hb-100 c-e0e9ef fz-16"
                                :class="`wb-${
                                  Math.floor(100 / columns.length) || 32
                                }`"
                                v-for="(columns, j) in columns"
                                :key="j + index"
                              >
                                <div v-if="columns.prop == 'rank'" class="rank">
                                  <div class="align-center">
                                    <div class="w-16 h-20 mr-6">
                                      <img
                                        :src="imageUrl + 'screen_048.png'"
                                        alt=""
                                        class="wb-100 hb-100"
                                        v-if="item[columns.prop] == 1"
                                      />
                                      <img
                                        :src="imageUrl + 'screen_049.png'"
                                        alt=""
                                        class="wb-100 hb-100"
                                        v-else-if="item[columns.prop] == 2"
                                      />
                                      <img
                                        :src="imageUrl + 'screen_050.png'"
                                        alt=""
                                        class="wb-100 hb-100"
                                        v-else-if="item[columns.prop] == 3"
                                      />
                                      <img
                                        :src="imageUrl + 'screen_051.png'"
                                        alt=""
                                        class="wb-100 hb-100"
                                        v-else
                                      />
                                    </div>
                                    <div>No.{{ item[columns.prop] }}</div>
                                  </div>
                                </div>
                                <div v-else-if="columns.prop == 'gender'">
                                  {{ ["女", "男"][item[columns.prop]] }}
                                </div>
                                <div v-else>
                                  {{ item[columns.prop] }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </TransitionGroup>
                      </template>

                      <div class="t-w fz-20 mt-30 center" v-else>暂无数据</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </trampoline-card>
        </div>
      </div>
      <!-- 中间 -->
      <div class="hb-100 wb-50 pl-51 pr-47 violet-center pt-41">
        <div
          class="wb-100 column center-banner hb-72 wb-100 pl-32 pr-32 pb-26 pt-36"
        >
          <div class="banner-title wb-100 hb-10 center">
            <img
              :src="imageUrl + 'screen_035.png'"
              alt=""
              class="w-457 hb-100"
            />
          </div>
          <div class="hb-2"></div>
          <div class="banner-contain hb-88">
            <img src="@/assets/img/35.jpg" alt="" class="wb-100 hb-100" />
            <!-- <video
              src="@/assets/video/跑操.mp4"
              class="wb-100 hb-100"
              style="object-fit: fill"
              autoplay
              muted
              loop
            ></video> -->
          </div>
        </div>
        <div class="center-footer wb-100 hb-28"></div>
      </div>
      <!-- 右边 -->
      <div class="hb-100 wb-25 pl-27 violet-right">
        <div class="wb-100 hb-50">
          <trampoline-card
            className="wb-100 hb-100"
            cardTitle="运动负荷"
            cardTitleClassName="card-title"
            cardClassName="wb-100 hb-100"
          >
            <template #right>
              <div
                class="align-center right-icon fz-20 pointer SC-Regular"
                @click="handleGoLoad"
              >
                <div class="mr-4">详情</div>
                <img
                  :src="imageUrl + 'screen_039.png'"
                  alt=""
                  class="w-22 h-12"
                />
              </div>
            </template>
            <div class="wb-100 hb-100">
              <div class="wb-100 hb-50 align-center column pt-17">
                <div
                  class="w-179 h-127 align-center column exercise-load row-start pt-21"
                >
                  <div class="align-center load-num load-1 lh-34">
                    <div class="load-num fz-36 tw-bold">
                      <numberAnimation
                        :value="sportsLoad.warmCount"
                        :animation_time="1"
                        numType="num"
                        class="fz-36 load-num"
                      />
                    </div>
                    <div class="load-unit fz-18 pt-10">人</div>
                  </div>
                  <div class="load-name fz-16">热身状态</div>
                </div>
                <div class="wb-100 row-center t-w fz-20 lh-34 SC-Regular">
                  {{ "bpm < 120" }}
                </div>
              </div>
              <div class="wb-100 align-center hb-50">
                <div class="wb-50 hb-100 align-center column">
                  <div
                    class="w-179 h-127 align-center column exercise-load row-start pt-21"
                  >
                    <div class="align-center load-2 lh-34">
                      <div class="load-num fz-36 tw-bold">
                        <numberAnimation
                          :value="sportsLoad.continuousCount"
                          :animation_time="1"
                          numType="num"
                          class="fz-36"
                        />
                      </div>
                      <div class="load-unit fz-18 pt-10">人</div>
                    </div>
                    <div class="load-name fz-16">持续运动</div>
                  </div>
                  <div class="wb-100 row-center t-w fz-20 lh-34 SC-Regular">
                    {{ "120< bpm <180" }}
                  </div>
                </div>
                <div class="wb-50 hb-100 align-center column">
                  <div
                    class="w-179 h-127 align-center column exercise-load row-start pt-21"
                  >
                    <div class="align-center load-3 lh-34">
                      <div class="load-num fz-36 tw-bold">
                        <numberAnimation
                          :value="sportsLoad.extremeCount"
                          :animation_time="1"
                          numType="num"
                          class="fz-36"
                        />
                      </div>
                      <div class="load-unit fz-18 pt-10">人</div>
                    </div>
                    <div class="load-name fz-16">极限预警</div>
                  </div>
                  <div class="wb-100 row-center t-w fz-20 lh-34 SC-Regular">
                    {{ "bpm > 180" }}
                  </div>
                </div>
              </div>
            </div>
          </trampoline-card>
        </div>
        <div class="wb-100 hb-50">
          <trampoline-card
            className="wb-100 hb-100"
            cardTitle="平均心率"
            cardTitleClassName="card-title"
            cardClassName="wb-100 hb-100"
          >
            <div class="wb-100 hb-100">
              <div class="average-contain wb-100 hb-100">
                <div class="wb-100 hb-100" ref="averageLineRef"></div>
              </div>
            </div>
          </trampoline-card>
        </div>
      </div>
    </div>
    <div class="violet-footer"></div>
  </div>
</template>

<script>
import { imageUrl } from "@/common/common";
import trampolineCard from "./components/trampolineCard.vue";
import {
  sportsLoadListEnums,
  Accuracy_Ranking_Column,
  Sport_Indicators,
  Average_Hear_Rate_Line,
} from "./enums/index";
import numberAnimation from "@/components/numberAnimation";
import { rand, sumKey } from "@/utils/utils";
import { addZero } from "@/utils/time";
export default {
  components: {
    trampolineCard,
    numberAnimation,
  },
  data() {
    return {
      imageUrl,
      downIntervalTime: null,
      downTime: "",
      isFull: false,
      studentList: [],
      school_id: 53,
      class_id: 808,
      schedule_id: 224446,
      studentsNumber: 1191,
      sportIndicators: Sport_Indicators,
      averageHearRate: Average_Hear_Rate_Line,
      sportsLoadList: sportsLoadListEnums,
      columns: Accuracy_Ranking_Column,
      dataList: [],
      centerLevel: {},
      centerTop: {},
      sportsLoad: {
        continuousCount: 0,
        warmCount: 0,
        extremeCount: 0,
      },
      averageLine: null,
      averageRate: "",
      averageMaxRate: "",
      second: 0,
      isGetData: true,
    };
  },
  created() {
    this.handleDownTime();
    this.getQuery();
  },
  mounted() {
    this.get_data();
    if (this.isGetData) {
      this.getHeart();
    }

    clearInterval(this.timeInter);
    this.timeInter = setInterval(() => {
      if (this.isGetData) {
        this.getHeart();
      }
      this.get_data();
    }, 5000);

    setTimeout(() => {
      this.getAverageCharts();
      this.WatchResize();
    }, 200);

    document.addEventListener("fullscreenchange", this.logFullScreen);
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.logFullScreen);
  },
  methods: {
    getQuery() {
      const { school_id, class_id, total, schedule_id } = this.$route.query;
      this.school_id = school_id ? school_id : 53;
      this.class_id = class_id ? class_id : 808;
      this.studentsNumber = total ? total : 1191;
      this.schedule_id = schedule_id ? schedule_id : 224446;
    },
    async get_data() {
      const { school_id } = this;
      const res = await this.axios.get("courses/polling/" + school_id);
      if (res.data.code === 1) {
        const { data } = res.data;
        this.studentList = data.students.sort(
          (a, b) => b.heart_avg - a.heart_avg
        );

        // const arr = data.students
        //   .map((item, index) => {
        //     return {
        //       ...item,
        //       heart_avg: rand(140, 160),
        //     };
        //   })
        //   .sort((a, b) => b.heart_avg - a.heart_avg);

        // this.dataList = arr.map((item, index) => {
        //   return {
        //     ...item,
        //     rank: index + 1,
        //   };
        // });

        this.dataList = data.students.map((item, index) => {
          return {
            ...item,
            rank: index + 1,
          };
        });

        this.centerLevel = data.centerLevel;
        this.centerTop = data.centerTop;

        this.setData();
      }
    },
    // 获取平均心率数据
    async getHeart() {
      const { school_id, class_id, schedule_id } = this;
      const res = await this.axios.get(
        "heart?schedule_id=" + schedule_id + "&class_id=" + class_id
      );
      if (res.status == 200 && res.data.code == 1) {
        const data = res.data.data;
        this.averageHearRate.xAxis.data = data.time.slice(-10);
        this.averageHearRate.series[0].data = data.all
          .map((item) => Number(item))
          .slice(-10);
        this.averageHearRate.series[1].data = data.boy
          .map((item) => Number(item))
          .slice(-10);
        this.averageHearRate.series[2].data = data.girl
          .map((item) => Number(item))
          .slice(-10);

        this.averageHearRate.series.forEach((item, index) => {
          if (item.data && item.data.length) {
            item.min = Math.min(...item.data);
          } else {
            item.min = 0;
          }
        });

        let minArr = this.averageHearRate.series.map((item) => item.min);

        let min = Math.min(...minArr);

        this.averageHearRate.yAxis.min = min;
        if (this.averageLine) {
          this.averageLine.setOption(this.averageHearRate);
        }
      }
    },
    averageHeartRate() {
      let classTime = this.second;
      var minute = Math.floor(classTime / 60) % 60;
      var second = classTime % 60;
      const time = addZero(minute) + ":" + addZero(second);

      this.averageHearRate.xAxis.data.push(time);

      if (this.averageHearRate.xAxis.data.length > 10) {
        this.averageHearRate.xAxis.data.shift(0);
      }

      this.averageHearRate.series.forEach((item, index) => {
        const totalArr = this.dataList;
        const manArr = totalArr.filter((item) => item.gender == 1);
        const femaleArr = totalArr.filter((item) => item.gender == 0);

        const totalTotalRate = sumKey(totalArr, "heart_avg");
        const manTotalRate = sumKey(manArr, "heart_avg");
        const femaleTotalRate = sumKey(femaleArr, "heart_avg");

        this.averageRate = Math.floor(totalTotalRate / totalArr.length);
        const manAverageRate = Math.floor(manTotalRate / manArr.length);
        const femaleAverageRate = Math.floor(
          femaleTotalRate / femaleArr.length
        );

        this.averageMaxRate = this.dataList[0].heart_avg;
        if (index == 0) {
          var num = this.averageRate;
          item.data.push(num);
        } else if ((index = 1)) {
          var num = manAverageRate;
          item.data.push(num);
        } else {
          var num = femaleAverageRate;
          item.data.push(num);
        }

        item.min = Math.min(...item.data);

        if (item.data.length > 10) {
          item.data.shift(0);
        }
      });

      this.averageHearRate.series.forEach((item, index) => {
        if (item.data && item.data.length) {
          item.min = Math.min(...item.data);
        } else {
          item.min = 0;
        }
      });

      let minArr = this.averageHearRate.series.map((item) => item.min);

      let min = Math.min(...minArr);

      this.averageHearRate.yAxis.min = min;
      if (this.averageLine) {
        this.averageLine.setOption(this.averageHearRate);
      }
    },
    setData() {
      if (!this.isGetData) {
        this.averageHeartRate();
      }

      this.setExerciseLoad();

      const { centerLevel, centerTop, studentList } = this;

      // 靶心率
      let timeList = this.studentList.map((item, index) => {
        return item.time;
      });

      let time = Math.max(...timeList);

      if (time > 25 * 60) {
        time = 25 * 60;
      }

      this.targetHeartTime = rand(time * 0.69, time * 0.7);
      this.sportIndicators.forEach((item, index) => {
        switch (item.key) {
          case "participants":
            item.value = this.studentsNumber;
            break;
          case "averageRate":
            if (this.isGetData) {
              item.value = centerLevel.classes[0].heart_avg || 0;
            } else {
              item.value = this.averageRate;
            }
            break;
          case "averageMaxRate":
            if (this.isGetData) {
              item.value = centerLevel.classes[0].heart_max || 0;
            } else {
              item.value = this.averageMaxRate;
            }
            break;
          case "averageDensity":
            item.value = centerTop.density || 0;
            break;
          case "averageIntensity":
            item.value = rand(60, 70);
            break;
          case "movementTime":
            item.value = this.targetHeartTime;
            break;
        }
      });

      this.second += 5;
    },
    getAverageCharts() {
      const averageLineRef = this.$refs.averageLineRef;
      this.averageLine = this.$echarts.init(averageLineRef);
      this.averageLine.setOption(this.averageHearRate);
    },
    setExerciseLoad() {
      this.sportsLoad.warmCount = this.dataList.filter(
        (item) => item.heart_avg < 120
      ).length;
      this.sportsLoad.continuousCount = this.dataList.filter(
        (item) => item.heart_avg >= 120 && item.heart_avg <= 180
      ).length;
      this.sportsLoad.extremeCount = this.dataList.filter(
        (item) => item.heart_avg > 180
      ).length;

      this.sportsLoadList.forEach((item) => {
        Object.keys(this.sportsLoad).forEach((key) => {
          if (item.key === key) {
            item.value = this.sportsLoad[key];
          }
        });
      });
    },

    // 监控页面的大小变化
    WatchResize() {
      const that = this;
      window.addEventListener("resize", function () {
        that.averageLine && that.averageLine.resize();
      });
    },

    handleGoLoad() {
      this.$router.push({
        path: "/exerciseLoad",
        query: {
          school_id: this.school_id,
          class_id: this.class_id,
          schedule_id: this.schedule_id,
        },
      });
    },
    // 倒计时
    handleDownTime() {
      if (this.downIntervalTime) {
        clearInterval(this.downIntervalTime);
      }

      let weekArr = new Array("日", "一", "二", "三", "四", "五", "六");
      this.downIntervalTime = setInterval(() => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const day = new Date().getDate();
        const houer = new Date().getHours();
        const minutes = new Date().getMinutes();
        const seconds = new Date().getSeconds();
        let week = new Date().getDay();

        this.downTime =
          year +
          "-" +
          this.handleZeroFill(month) +
          "-" +
          this.handleZeroFill(day) +
          " " +
          this.handleZeroFill(houer) +
          ":" +
          this.handleZeroFill(minutes) +
          "  " +
          "星期" +
          weekArr[week];
      }, 1000);
    },
    handleZeroFill(num) {
      if (num < 10) {
        return "0" + num;
      }
      return num;
    },
    handleScreen() {
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.requestFullScreen();
      } else {
        this.exitFullscreen();
      }
    },

    requestFullScreen() {
      let de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      }
    },

    exitFullscreen() {
      let de = document;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
      }
    },

    logFullScreen() {
      if (document.fullscreenElement) {
        this.isFull = true;
      } else {
        this.isFull = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.violet-contain {
  background: radial-gradient(#0b2465 32%, #030825 100%);
  .violet-head {
    width: 100%;
    height: 10%;
    background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_042.png")
      no-repeat;
    background-size: 100% 100%;
    .head-center {
      font-family: "www.alltoall.net_notosansscmedium-4._2Pa03Y7x4t", sans-serif;
    }
  }
  .violet-centent {
    width: 100%;
    height: 83%;
    .violet-left {
      .indocators-item {
        width: 50%;
        height: 33%;
        .item-title {
          font-size: 0.18rem;
          color: rgba(255, 255, 255, 0.85);
          margin-bottom: 0.05rem;
          font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
        }
        .item-num {
          font-family: "D-DIN-PRO-700-Bold", sans-serif;
          font-weight: bold;
          background: linear-gradient(#e0e9ef 0%, #006aad 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .item-unit {
          font-size: 0.14rem;
          color: rgba(180, 192, 204, 1);
          font-weight: normal;
          font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
        }
      }
    }
    .violet-center {
      .center-banner {
        width: 100%;
        background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_036.png")
          no-repeat;
        background-size: 100% 100%;
        .w-457 {
          width: 4.57rem;
        }
        .banner-contain {
          width: 100%;
        }
      }
      .center-footer {
        background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_038.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }

    .violet-right {
      .right-icon {
        color: rgba(3, 181, 241, 1);
      }

      .exercise-load {
        background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_040.png")
          no-repeat;
        background-size: 100% 100%;
        .load-name {
          color: rgba(224, 233, 239, 0.85);
          font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
        }

        .load-unit {
          font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
        }

        .load-1 {
          color: rgba(114, 223, 245, 1);
        }
        .load-2 {
          color: rgba(255, 192, 76, 1);
        }
        .load-3 {
          color: rgba(255, 81, 75, 1);
        }
      }
    }
  }

  .violet-footer {
    width: 100%;
    height: 7%;
    background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_041.png")
      no-repeat;
    background-size: 100% 100%;
  }
}

.SC-Regular {
  font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
}

.load-num {
  font-family: "D-DIN-PRO-500-Medium", sans-serif;
}

/* 1. 声明过渡效果 */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. 声明进入和离开的状态 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(0.3rem, 0);
}

/* 3. 确保离开的项目被移除出了布局流
      以便正确地计算移动时的动画效果。 */
.fade-leave-active {
  position: absolute;
}
.th {
  font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
}

.tr {
  font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
  .rank {
    color: rgba(224, 233, 239, 1);
  }
  background: linear-gradient(
    90deg,
    rgba(16, 46, 64, 1) 0%,
    rgba(3, 8, 37, 1) 100%
  );
  border: 0.01rem solid;
  border-image: linear-gradient(
      90deg,
      rgba(211, 245, 255, 0.15),
      rgba(3, 8, 37, 1)
    )
    1 1;
  border-radius: 0.02rem;

  &:nth-of-type(1) {
    .rank {
      color: rgba(255, 184, 0, 1);
    }
    border-image: linear-gradient(90deg, rgba(0, 75, 134, 1), rgba(3, 8, 37, 1))
      1 1;
    background: linear-gradient(
      90deg,
      rgba(7, 50, 81, 1) 0%,
      rgba(3, 8, 37, 1) 100%
    );
  }
  &:nth-of-type(2) {
    .rank {
      color: rgba(35, 255, 229, 1);
    }
    background: linear-gradient(
      90deg,
      rgba(18, 56, 70, 1) 0%,
      rgba(6, 23, 35, 1) 100%
    );
    border-image: linear-gradient(
        90deg,
        rgba(31, 102, 107, 1),
        rgba(3, 8, 37, 1)
      )
      1 1;
  }
  &:nth-of-type(3) {
    .rank {
      color: rgba(25, 178, 226, 1);
    }
    background: linear-gradient(
      90deg,
      rgba(16, 46, 64, 1) 0%,
      rgba(3, 8, 37, 1) 100%
    );
    border-image: linear-gradient(90deg, rgba(11, 64, 96, 1), rgba(3, 8, 37, 1))
      1 1;
  }
}
.tr:nth-child(2n) {
  // background-color: #293c82;
}

.c-e0e9ef {
  color: #e0e9ef;
}
</style>
