<template>
  <div class="card" :class="className">
    <div
      class="wb-100 h-45 t-w fz-28 align-center space-between pl-41 pr-32"
      :class="cardTitleClassName"
    >
      <div class="title">{{ cardTitle }}</div>
      <div class="card-title-right">
        <slot name="right" />
      </div>
    </div>
    <div class="card-body" :class="cardClassName">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    cardTitle: {
      type: String,
      default: "",
    },
    cardClassName: {
      type: String,
      default: "",
    },
    cardTitleClassName: {
      type: String,
      default: "card-title",
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_037.png")
    no-repeat;
  background-size: 100% 100%;
  .title {
    font-family: "ysbthzt", sans-serif;
  }
}

.card-body {
  height: calc(100% - 0.45rem);
  width: 100%;
}
</style>
